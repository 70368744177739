/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import 'bootstrap';
import '@fortawesome/fontawesome-free';
import 'jquery-timepicker/jquery.timepicker.js';
import "./frontC";

import './styles/app.css';
import $ from 'jquery';
import 'jquery-validation'
import autocomplete from 'autocomplete.js/dist/autocomplete.jquery';
// start the Stimulus application
import './bootstrap';
import Swal from "sweetalert2";


Window.jQuery = $;
Window.$ = $;
